import React, { useState, useEffect } from 'react';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import _ from 'lodash';

const FastboardSettings = (props) => {

    return (
        <div>
            {(() => {
                if (props.fastboardstatus == true) {
                    return (
                        <div className="links" title='Open Fastboard' onClick={() => {
                            props.getFastboardToken();
                            console.log("Fastbord====", props.fastboardtoken);
                            props.setFastboardStatus(false);
                            props.setLeaveScreenShare(false);
                            props.minimizeWin();
                            // props.openFastboardFun();
                        }
                        }>
                            <div className="text-center icon-holder-secondary">
                                <NoteAltIcon />
                                {/* <img className='icon-img' src={NoteAltIcon} /> */}
                                {/* <div className="linkContent hide">Mic off</div> */}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="links" title='Close Fastboard' style={!props.openFastboard ? { pointerEvents: "none", opacity: "0.4" } : {}} onClick={() => {
                            // props.closefastboardFun();
                            props.setOpenFastboard(false);
                            props.resetFastboardWin();
                            props.setFastboardStatus(true);
                            props.closeFastboard();
                            props.setLeaveScreenShare(true);
                        }}>
                            <div className="text-center icon-holder-primary ">
                                <NoteAltIcon />
                                {/* <img className='icon-img' src={NoteAltIcon} /> */}
                                {/* <div className="linkContent hide">Mic on</div> */}
                            </div>
                        </div>
                    )
                }
            })()}
        </div>
    )
}
export default FastboardSettings;