import { React, useState } from 'react';
import { useFastboard, Fastboard } from "@netless/fastboard-react";
// import '../../settings-view/style.css';
import '../../video-screen/style.css';

function CreateFastboard(props) {
    const fastboard = useFastboard(() => ({
        sdkConfig: {
            appIdentifier: "lFsVUHd8EeyRoMGwMgqdqg/UP5q_u4F4Hjx-w",
            region: "us-sv", // "cn-hz" | "us-sv" | "sg" | "in-mum" | "gb-lon"
        },
        joinRoom: {
            uid: props.query,
            uuid: props.fastboardtoken.uuid,
            roomToken: props.fastboardtoken.resp
        }
    }
    ))

    const FastboardUIConfig = { toolbar: { apps: { enable: false } } }

    // const ApplianceNames = {
    //     appliance: {
    //         "laserPointer"
    //     }
    // }
    // Container must have a visible size
    return (
        <div className="getFastboard" style={{ width: '-webkit-fill-available', background: 'white' }} >
            <Fastboard app={fastboard} config={FastboardUIConfig} />
        </div>

    )
}

export default CreateFastboard;