import React, { useState } from 'react';
import { useEffect } from 'react';
import fullScreenImg from '../../../../src/assets/img/icon/fullscreen.svg';

import {
    client,
    cameraTrack
} from '../index';
const FullScreen = (props) => {
    const [fullscreenstatus, setfullscreenstatus] = useState();
    useEffect(() => {
        if (document.addEventListener)
        {
            document.addEventListener('fullscreenchange', exitHandler, false);
            document.addEventListener('mozfullscreenchange', exitHandler, false);
            document.addEventListener('MSFullscreenChange', exitHandler, false);
            document.addEventListener('webkitfullscreenchange', exitHandler, false);
        }
        function exitHandler()
        {
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement)
            {
                setfullscreenstatus(true);
            }
        }
    },[])
    const toggleFullScreen = () => {
        let element = document.documentElement;

        element.requestFullscreen({ navigationUI: "show" })
            .then(function () {
                // element has entered fullscreen mode successfully
                setfullscreenstatus(false);
            })
            .catch(function (error) {
                // element could not enter fullscreen mode
                // error message
            });
    }
    const toggleExitScreen = () => {
        document.exitFullscreen()
            .then(function () {
                // element has exited fullscreen mode
                setfullscreenstatus(true);

            })
            .catch(function (error) {
                // element could not exit fullscreen mode
                // error message
            });
    }
    return (
        <div>
            {(() => {
                if (fullscreenstatus === false) {
                    return (
                        <div className="links" title='Close full screen' onClick={toggleExitScreen}>
                            <div className="text-center icon-holder-primary">
                            <img  className='icon-img' src={fullScreenImg} />
                                {/* <div className="linkContent hide">Exit screen</div> */}
                            </div>
                        </div>
                    )

                } else {
                    return (
                        <div className="links" title='Open full screen' onClick={toggleFullScreen}>
                            <div className="text-center icon-holder-secondary">
                                {/* <i class="fa fa-expand" ></i> */}
                                <img  className='icon-img' src={fullScreenImg} />
                                {/* <div className="linkContent hide">Full screen</div> */}
                            </div>
                        </div>
                    )

                }
            })()}

        </div>
    )
}
export default FullScreen;
