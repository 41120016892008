import React, { useState, useEffect } from 'react';
import resourceImg from '../../../../src/assets/img/icon/settings.svg';
import Modal from 'react-modal';
import Devices from '../modal/settings-modal/devices';
import SettingsView from '../../settings-view';
import _ from 'lodash';

const Settings = (props) => {

    let searchParams = new URLSearchParams(window.location.search);
    const role = searchParams.get('role');

    const [modalstatus, setModalStatus] = useState(false);
    const [bgText, setBgText] = useState('Remove');
    const [deviceModal, setDeviceModal] = useState(true);
    const closeModal = () => {
        setModalStatus(!modalstatus)
    };

    const openDeviceModal = () => {
        setDeviceModal(true);
        closeModal();
    }

    // useEffect(() => {
    //     const element = document.getElementById('setting1');
    //     console.log("element==", element);
    //     const element1 = document.getElementsByClassName('App')[0]
    //     console.log("element1==", element1);
    //     let parentDiv = element1.parentNode;
    //     parentDiv.insertBefore(element, element1);
    // }, [])
    // Change Text as per background status
    // useEffect(() => {
    //     setBgText(!props.backgroundStatus ? 'Add' : 'Remove')
    // }, [props.backgroundStatus]);

    //   const changeBgStatus = () => {
    //       props.setBackgroundstatus(bgText == 'Remove' ? false : true)
    //       setBgText(bgText == 'Remove' ? 'Add' : 'Remove')
    //       //closeModal();
    //   }

    return (
        <div>
            {(() => {
                return (
                    <div>
                        <div class="links" onClick={() => { props.setSettingModalStatus(!props.settingModalStatus) }}>
                            {props.settingModalStatus ? <div title='Close settings' class="text-center icon-holder-primary">
                                <img className='icon-img' src={resourceImg} />
                                {/* <div class="linkContent hide">Settings</div> */}
                            </div> : <div title='Open settings' class="text-center icon-holder-secondary" >
                                <img className='icon-img' src={resourceImg} />
                                {/* <div class="linkContent hide">Settings</div> */}
                            </div>}
                        </div>
                        {/* {modalstatus ?
                        <SettingsView {...props} handleClose={() => setModalStatus(false)} />
                        : null} */}

                        {/* { deviceModal && <Devices modalstatus={deviceModal} setDeviceModal={setDeviceModal}/>}  */}

                    </div>
                )
            })()}
        </div>
    )
}
export default Settings;