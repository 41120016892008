import React, { useState } from 'react';
import { useEffect } from 'react';
//import chatIcon from '../../../../src/assets/img/icon/chat_bubble.svg';

const BackgroundSetting = (props) => {
    //const [chatStatus, setChatStatus] = useState();

    useEffect(() => {

    }, []);
    
    return (
        <div>
            <div style={{'padding': '0px 25px'}}>
              <div className='audio-lable' style={{'color':'#E82C86'}}>Background</div>
                <div className="background-container">
                    <div onClick={() => props.setBackgroundstatus(false)} className={props.backgroundStatus == false ? 'remove-background active-background' : 'remove-background'}></div>
                    <div onClick={() => props.setBackgroundstatus('blur')} className={props.backgroundStatus == 'blur' ? 'blur-background active-background' : 'blur-background'}></div>
                    <div onClick={() => props.setBackgroundstatus(true)} className={props.backgroundStatus == true ? 'set-background active-background' : 'set-background'}></div> 
                </div>
            </div>
        </div>
    )
}
export default BackgroundSetting;