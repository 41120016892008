import React, { useState, useEffect } from 'react';
import ScreenShare from './screen-share-func';
import MicScreen from './mic-func';
import CamScreen from './cam-func';
import RecordScreen from './record-func';
import FullScreen from './full-screen-func';
import ChatButton from './chat-func';
import ResourceScreen from './resource-func';
import Settings from './settings';
import FastboardSettings from './fastboardSettings';
import LeaveStream from './leave-func';

import _ from 'lodash';

const ButtonScreen = (props) => {
   
    const [cameraStatus, setCameraStatus] = useState(true);
    const [leaveCallStatus, setLeaveCallStatus] = useState(false);
    const [updateRecordFun, setUpdateRecordFun] = useState();

    let searchParams = new URLSearchParams(window.location.search);
    const role = searchParams.get('role');
    //const isMobile = navigator.userAgentData.mobile; //resolves true/false
    let isMobile = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    } else {
        isMobile = false;
    }
    //let bbbb;
    const updateRecording = (recordingAPI) => {
        setUpdateRecordFun(() => {
            return recordingAPI })
        //bbbb = recordingAPI
    }
    useEffect(() => {
        if(props.triggerSubscripe == false){
            updateRecordFun(false, props.Users, () => {});
        }
        if(props.triggerSubscripe == true){
            updateRecordFun(true, props.Users, () => {});
        }
    }, [props.triggerSubscripe])
   

    return (
        <div className="icon-container">

            <div className="help-icon-wrapper" title='Watch Video Tutorial' onClick={() => { window.open("https://youtu.be/xEe13XAZifk", '_blank'); }}><span className='help-icon'>i</span> Help</div>
            <div className="icons">
                <CamScreen setCameraStatus={setCameraStatus} />
                <MicScreen />
                {_.isEqual(isMobile, false) && _.size(_.get(props, 'Users')) > 1
                    && props.leaveScreeShare
                    && <ScreenShare updateRecordFun={updateRecordFun}  {...props} />}
                {(<React.Fragment>
                    {/* <ResourceScreen /> */}
                    <ChatButton messages={props.messages} />
                    {_.isEqual(role, "teacher") && <RecordScreen updateRecording={updateRecording} leaveCallStatus={leaveCallStatus} {...props} />}
                    <Settings cameraStatus={cameraStatus} {...props} />
                </React.Fragment>)
                }
                {/* <LeaveStream /> */}
                {/* <div class="links">
                <div class="text-center">
                    <i class="fa fa-cog" ></i>
                    <div class="linkContent hide">Resources</div>
                </div>
            </div> */}
                {/* <div class="links">
                <div class="text-center">
                    <i class="fa fa-comment-o" ></i>
                    <div class="linkContent hide">Chat</div>
                </div>
            </div> */}
                {_.isEqual(isMobile, false) && props.userLeftStatus == true && _.size(_.get(props, 'Users')) < 3
                    && props.checkstatus == true && props.leaveFastboard ?
                    < FastboardSettings {...props} /> : null
                }
                <FullScreen />
                <LeaveStream setLeaveCallStatus={setLeaveCallStatus} />
            </div>
        </div >
    )
}
export default ButtonScreen;