import './App.css';
import VideoScreen from './containers/video-screen';
import SplashScreen from './containers/waiting-screen';
import { Mixpanel } from './utils/Mixpanel';
import { MIX_PANEL_CONST } from './constants/MixpanelConstants';
import React, { useEffect, useState, useCallback } from 'react';
import '../node_modules/antd/dist/antd.css';
import chat1 from './assets/img/chat1.png';
import chat2 from './assets/img/chat2.png';
import AgoraRTM from "agora-rtm-sdk";
//import { client } from "./utils/client";
import { Widget, addResponseMessage } from 'react-chat-widget';

import '../node_modules/react-chat-widget/lib/styles.css';

function App() {
  //Todo: let move this to context and use across application
  // sessionStorage.removeItem("fastboardtoken");
  let searchParams = new URLSearchParams(window.location.search);
  let channel;
  const cname = searchParams.get('cname');
  const id = searchParams.get('id');
  const role = searchParams.get('role');
  const name = searchParams.get('username');

  const [messages, setMessages] = useState([]);
  const [stateChannel, setChannel] = useState();
  const [apiresponse, getApiresponse] = useState(false);
  const [showWaitScreen, setShowWaitScreen] = useState(true);
  const [recortingStatus, setRecortingStatus] = useState();
  const [showRecortingIcon, setShowRecortingIcon] = useState();
  const [isStudentJoined, setIsStudentJoined] = useState();
  const [openFastboard, setOpenFastboard] = useState(false);
  const [fastboardtoken, setFastboardtoken] = useState();
  const [checkstatus, setCheckStatus] = useState();
  const [triggerFun, setTriggerFun] = useState(false);
  const [reviewFun, setReviewFun] = useState(true);
  const [checkData, setCheckData] = useState();
  const [leaveScreeShare, setLeaveScreenShare] = useState(true);
  const [leaveFastboard, setLeaveFastboard] = useState(true);
  const [userLeftStatus, setUserLeftStatus] = useState(false);

  const getFastboardToken = () => {
    if (role == "teacher") {
      if (sessionStorage.getItem("fastboardtoken")) {
        setTimeout(() => {
          console.log("checkData==", checkData);
          sendEvents('getFastboard', { 'fastboardtoken': checkData });
          setFastboardtoken(checkData);
          setOpenFastboard(true);
        }, 2000);
      } else {
        fetch(process.env.REACT_APP_BASE_URL + '/roomToken')
          .then((response) => response.json())
          .then((res) => {
            setCheckData(res);
            sendEvents('getFastboard', { 'fastboardtoken': res });
            sessionStorage.setItem("fastboardtoken", JSON.stringify(res));
            setFastboardtoken(res);
            setOpenFastboard(true);
          })
      }
    }
  }

  // useEffect(() => {
  //   if (role !== 'teacher') {
  //     sendEvents('I am Web');
  // if (/Android|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //   sendEvents('getDevice', { indetifyDevice: false });
  // }
  // else {
  //   sendEvents('getDevice', { indetifyDevice: true });
  // }
  //   }
  // }, [])

  useEffect(() => {
    sessionStorage.removeItem("fastboardtoken");
  }, [])

  useEffect(() => {
    if (role == "teacher") {
      setCheckStatus(true);
    }
  })

  const closeFastboard = () => {
    if (role == 'teacher') {
      setOpenFastboard(false);
      sendEvents('closeFastboard', { 'fast': false });
    }
  }

  useEffect(() => {
    if (role == "teacher") {
      sendEvents('recording', { ...{ recortingStatus } });
      console.log("recortingStatus==", { ...{ recortingStatus } });
      setShowRecortingIcon(recortingStatus);
    }
  }, [recortingStatus]);

  useEffect(() => {
    if (role != "teacher" && stateChannel) {
      setTimeout(() => {
        sendEvents('requestRecordingStatus');
        sendEvents('checkFastboard');
      }, 1000);
    }
  }, [stateChannel]);

  useEffect(() => {
    if (role != "teacher") {
      setShowWaitScreen(true);
    }
    else {
      setShowWaitScreen(false);
    }
  }, [role]);

  useEffect(async () => {
    console.log('useEffect', id, cname, process.env);
    Mixpanel.identify(id);
    Mixpanel.track(MIX_PANEL_CONST.VIDEO_CALL_REQUEST_RECEIVED);
    Mixpanel.superproperty.set({ userId: id, channelName: cname });
    //localStorage.removeItem("activeVideoDevice");
    //localStorage.removeItem("activeAudioDevice");

    let tokenResJson;
    try {
      let tokenRes = await fetch(process.env.REACT_APP_BASE_URL + '/rtmtoken', {
        method: "POST",
        body: JSON.stringify({
          user: id
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      tokenResJson = await tokenRes.json();
      getApiresponse(true);
    } catch (err) {
      console.log('error while creating token...');
    }

    // create channel for chat
    let options = {
      uid: id,
      token: tokenResJson?.token
    };
    const client = AgoraRTM.createInstance(process.env.REACT_APP_ID);
    //console.log(client);
    await client.login(options);
    channel = client.createChannel(cname);
    setChannel(channel);
    await channel.join();
    console.log("channel joined");
    channel.on("ChannelMessage", (message, memberId) => receivedMessage(message, memberId));
  }, [id, cname]);

  const handleWaitScreen = (e) => {
    setShowWaitScreen(e);
  }

  const accessFastboard = (data) => {
    console.log("data", data);
    setReviewFun(false);
    setCheckStatus(false);
    setFastboardtoken(data);
    setOpenFastboard(true);
    setLeaveScreenShare(false);
    setTriggerFun(true);
    setTimeout(() => {
      setTriggerFun(true);
    }, 4000)
  }

  const endFastboard = (data) => {
    setReviewFun(true);
    setOpenFastboard(data);
    setLeaveScreenShare(true);
    setTimeout(() => {
      setTriggerFun(false);
    }, 3000)
  }

  const iamWeb = () => {
    if (role !== 'teacher') {
      sendEvents('web');
    }
  }

  function receivedMessage(message, memberId) {
    const receivedMessage = JSON.parse(message.text);
    if (receivedMessage.event) {
      if (receivedMessage.eventName == 'recording') {
        setShowRecortingIcon(receivedMessage.eventData.recortingStatus);
      } else if (receivedMessage.eventName == 'requestRecordingStatus') {
        console.log("recording is working");
        sendEvents('recording', { 'recortingStatus': !!localStorage.getItem('recording') });
      } else if (receivedMessage.eventName == 'getFastboard') {
        console.log("getFast==", receivedMessage.eventData.fastboardtoken);
        accessFastboard(receivedMessage.eventData.fastboardtoken);
      } else if (receivedMessage.eventName == 'closeFastboard') {
        endFastboard(receivedMessage.eventData.fast);
      } else if (receivedMessage.eventName == 'web') {
        setUserLeftStatus(true);
      }
      // else if (receivedMessage.eventName == 'checkFastboard') {
      //   console.log("checkFastboard");
      //   let data = sessionStorage.getItem("fastboardtoken");
      //   console.log("data===", data);
      //   if (data) {
      //     let data1 = JSON.parse(data);
      //     console.log("data1==", data1);
      //     sendEvents('getFastboard', { 'fastboardtoken': data1 });
      //   }
      // }
    } else {
      console.log("member id", memberId);
      console.log("channel message", message.text);
      console.log("old message", messages);

      console.log("receivedMessage message", receivedMessage);
      receivedMessage.id = messages.length + 1;
      addResponseMessage(receivedMessage.text);
      setMessages(messages => [...messages, receivedMessage])
    }
  }

  async function sendMessageClickHandler(mssg) {
    console.log("sendMessageClickHandler message", mssg)
    console.log("sendMessageClickHandler send mssg btn clicked");
    let messageFormatted = {
      id: messages.length + 1,
      text: mssg,
      event: false,
      createdAt: new Date(), // optional
      user: {
        id: id,
        avatar: 'https://lh3.googleusercontent.com/ogw/AOh-ky2p_iFrKzjqJneSk3WlEPyw_wUpqX1o55jySkIzSg=s32-c-mo' // optional
      }
    }
    const messageInStringFormat = JSON.stringify(messageFormatted);
    // console.log("sendMessageClickHandler send mssg btn channel",channel);
    console.log("sendMessageClickHandler send mssg btn stateChannel", stateChannel);

    if (stateChannel) {
      await stateChannel.sendMessage({ text: messageInStringFormat }).then(() => {
        console.log("old message", messages);
        setMessages(messages => [...messages, mssg])
      });
    }

  }

  const sendEvents = async (eventName, eventData = {}) => {
    let messageFormatted = {
      eventData: eventData,
      eventName: eventName,
      event: true,
      createdAt: new Date(),
    }
    const messageInStringFormat = JSON.stringify(messageFormatted);
    // console.log("sendMessageClickHandler send mssg btn channel",channel);
    console.log("sendMessageClickHandler send mssg btn stateChannel", stateChannel);

    if (stateChannel || channel) {
      await (stateChannel || channel).sendMessage({ text: messageInStringFormat }).then(() => {
        console.log("old message", messages);
        // setMessages(messages => [...messages, mssg])
      });
    }
  }

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    sendMessageClickHandler(newMessage)
    // Now send the message throught the backend API
  };

  var havePopupBlockers = ('' + window.open).indexOf('[native code]') === -1;
  return (
    <div className="App">
      {showRecortingIcon && <div className="recording-icon-wrapper" ><span className='recording-icon'>◉</span> RECORDING</div>}
      {/* {showWaitScreen && <SplashScreen name={name} />} */}
      <VideoScreen fastboardtoken={fastboardtoken} setRecortingStatus={setRecortingStatus} messages={messages} handleWaitScreen={handleWaitScreen} setOpenFastboard={setOpenFastboard} openFastboard={openFastboard} getFastboardToken={getFastboardToken} checkstatus={checkstatus} setCheckStatus={setCheckStatus} triggerFun={triggerFun} closeFastboard={closeFastboard} reviewFun={reviewFun} endFastboard={endFastboard}
        leaveScreeShare={leaveScreeShare} setLeaveScreenShare={setLeaveScreenShare} userLeftStatus={userLeftStatus} setUserLeftStatus={setUserLeftStatus}
        leaveFastboard={leaveFastboard} setLeaveFastboard={setLeaveFastboard} iamWeb={iamWeb}
      />
      {apiresponse &&
        <Widget
          title="Chat"
          subtitle=""
          messages={messages && messages}
          handleNewUserMessage={handleNewUserMessage}
          emojis={true}
          showCloseButton={true}
          profileClientAvatar={chat1}
          profileAvatar={chat2}
        />
      }
    </div>
  );
}

export default App;
