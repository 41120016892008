import mixpanel from 'mixpanel-browser';

let env_check = process.env.NODE_ENV;

if (env_check === "production") {
    mixpanel.init('2afbb43a734a03f159a10d48cac28ff6'); //Production Env
}
else {
    mixpanel.init('28266d26bcbfc7c2509201cb76e9660c'); //Dev
}

let actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
    superproperty: {
        set: (props) => {
            mixpanel.register(props);
        }
    }
}

export let Mixpanel = actions;