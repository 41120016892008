import React, { useEffect, useState } from 'react';
import './style.css';
import ButtonScreen from './button-func/index';
import AgoraRTC from "agora-rtc-sdk-ng"
import _ from 'lodash';
// import LeaveStream from './button-func/leave-func';
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import micoff from '../../assets/img/background.png';
import '../../initialNameScreen/index.css';
import MinMaxStream from './button-func/min-func';
import SettingsView from '../settings-view/index';
import { MIX_PANEL_CONST } from '../../constants/MixpanelConstants';
import { Mixpanel } from '../../utils/Mixpanel';
import CreateFastboard from './button-func/fastboard-func';
import axios from 'axios';
import WifiIndicator from 'react-wifi-indicator';
import { ReactInternetSpeedMeter } from 'react-internet-meter';
const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
AgoraRTC.enableLogUpload();
AgoraRTC.setLogLevel(1);
let cameraTrack = null;
let microphoneTrack = null;
const extension = new VirtualBackgroundExtension();
// Register the extension
AgoraRTC.registerExtensions([extension]);
let processor = null;
const videoCallProfileConfig = {"88":"480p_1"};


const VideoScreen = (props) => {

    let searchParams = new URLSearchParams(window.location.search);
    const cname = searchParams.get('cname');
    const query = searchParams.get('id');
    const role = searchParams.get('role');
    const username = searchParams.get('username');
    let UID = parseInt(query);
    let UIDStr = String(UID);
    //const UID = query;

    let remoteUsers = {}
    const [appdetails, setappdetails] = useState({
        appId: process.env.REACT_APP_ID,
        channelName: cname
    });
    const [Users, setUsers] = useState([])
    const [isOpened, setIsOpened] = useState(false);
    let countAll;
    const CHANNEL = _.get(appdetails, 'channelName');
    const APP_ID = _.get(appdetails, 'appId')
    let TOKEN;
    const [screensharestatus, setScreensharestatus] = useState();
    const [backgroundStatus, setBackgroundstatus] = useState(false);
    const [addfastboard, setAddFastboard] = useState();
    const [userJoined, setUserJoined] = useState(false);
    const [wifiStrength, setWifiStrength] = useState();
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const [settingModalStatus, setSettingModalStatus] = useState(false);
    const [fastStatus, setFastStatus] = useState();
    const [minFast, setMinFast] = useState(true);
    const [fastboardstatus, setFastboardStatus] = useState(true);
    const [triggerSubscripe, setTriggerSubscripe] = useState();
    // const [userLeftStatus, setUserLeftStatus] = useState(false);
    //const [checkSpeed, SetCheckSpeed] = useState();

    // User responsive/laptop view checks
    useEffect(() => {
        //popupBlocker()
        //const isMobile = navigator.userAgentData.mobile; //resolves true/false
        let isMobile = false;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            isMobile = true;
        } else {
            isMobile = false;
        }

    }, []);

    window.addEventListener("offline", (event) => {
        setIsOnline(false);
    });

    window.addEventListener("online", (event) => {
        setIsOnline(true);
    });


    useEffect(() => {
        console.log('backgroundStatus', backgroundStatus)
        backgroundStatus == 'blur' ? setBackgroundBlur() : backgroundStatus ? setBackgroundImage() : disableBackgroundImage()

    }, [backgroundStatus]);

    useEffect(() => {
        tokenapicall();
    }, [UID, client, CHANNEL,]);


    useEffect(() => {
        countAll = document.querySelectorAll('#user-sm-view .SmalViewContainer').length;
    }, [Users]);

    useEffect(() => {
        if (props.triggerFun == true) {
            // openFastboardFun()
            minimizeWin();
        }
    }, [props.triggerFun])

    // useEffect(() => {
    //     if (role !== 'teacher') {
    //         if (props.leaveScreenShare == true) {
    //             props.setLeaveFastboard(false);
    //         }
    //     }
    // })

    useEffect(() => {
        if (props.reviewFun == true) {
            // closefastboardFun();
            resetFastboardWin();
        }
    }, [props.reviewFun])

    const tokenapicall = () => {
        Mixpanel.track(MIX_PANEL_CONST.TOKEN_API_CALL_START, { ...{username : username}, ...appdetails });
        try {
            fetch(`${process.env.REACT_APP_BASE_URL}/generatetoken/${appdetails.channelName}/audience/uid/${UID}`)
                .then(data => {
                    return data.json();
                })
                .then(post => {
                    Mixpanel.track(MIX_PANEL_CONST.TOKEN_API_CALL_SUCCESS, { ...{username : username}, ...post });
                    TOKEN = _.get(post, 'token');
                    setappdetails(preValue => ({ ...preValue, ...post }));
                    setUsers(preValue => _.uniqBy([...preValue, UID]));
                    initvideocall()
                });
        } catch (e) {
            Mixpanel.track(MIX_PANEL_CONST.TOKEN_API_CALL_ERROR, e);
            console.log('**********Error while generating token');
        }
    }

    const initvideocall = async (post) => {
        await joinAndDisplayLocalStream();
        if (_.isEqual(role, 'teacher')) {
            setBackgroundImage();
            setBackgroundstatus(true);
        }
    }


    const getProcessorInstance = async () => {
        if (!processor && cameraTrack) {
            // Create a VirtualBackgroundProcessor instance
            processor = extension.createProcessor();

            try {
                // Initialize the extension and pass in the URL of the Wasm file
                await processor.init("https://api.artiumacademy.com/airmeetwasm");
            } catch (e) {
                console.log("exception", e);
                console.log("Fail to load WASM resource!"); return null;
            }
            // Inject the extension into the video processing pipeline in the SDK
            cameraTrack.pipe(processor).pipe(cameraTrack.processorDestination);
        }
        return processor;
    }

    AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            microphoneTrack.setDevice(changedDevice.device.deviceId);
            localStorage.setItem('activeAudioDevice', changedDevice.device.deviceId)
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === microphoneTrack.getTrackLabel()) {
            const oldMicrophones = await AgoraRTC.getMicrophones();
            oldMicrophones[0] && microphoneTrack.setDevice(oldMicrophones[0].deviceId);
            oldMicrophones[0] && localStorage.setItem('activeAudioDevice', oldMicrophones[0].deviceId)
        } else {
            navigator.mediaDevices.enumerateDevices().then(async (devices) => {
                if (!(devices.find(e => e.label == microphoneTrack._deviceName)?.deviceId)) {
                    let oldMicrophones = await AgoraRTC.getMicrophones();
                    oldMicrophones[0] && microphoneTrack.setDevice(oldMicrophones[0].deviceId);
                    oldMicrophones[0] && localStorage.setItem('activeAudioDevice', oldMicrophones[0].deviceId)
                }
            });
        }
    }

    AgoraRTC.onCameraChanged = async (changedDevice) => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            cameraTrack.setDevice(changedDevice.device.deviceId);
            localStorage.setItem('activeVideoDevice', changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === cameraTrack.getTrackLabel()) {
            const oldCameras = await AgoraRTC.getCameras();
            oldCameras[0] && cameraTrack.setDevice(oldCameras[0].deviceId);
            oldCameras[0] && localStorage.setItem('activeVideoDevice', oldCameras[0].deviceId);
        } else {
            navigator.mediaDevices.enumerateDevices().then(async (devices) => {
                if (!(devices.find(e => e.label == cameraTrack._deviceName)?.deviceId)) {
                    let oldCameras = await AgoraRTC.getCameras();
                    oldCameras[0] && cameraTrack.setDevice(oldCameras[0].deviceId);
                    oldCameras[0] && localStorage.setItem('activeVideoDevice', oldCameras[0].deviceId);
                }
            });
        }
    }

    // Get the downlink network condition
    // client.on("network-quality", (quality) => {
    //     console.log('==========network-quality=============', quality.downlinkNetworkQuality);
    //     setWifiStrength(quality.downlinkNetworkQuality);
    // });

    const setBackgroundImage = async () => {
        const imgElement = document.createElement('img');
        imgElement.src = micoff;

        imgElement.onload = async () => {
            let processor = await getProcessorInstance();
            try {
                processor.setOptions({ type: 'img', source: imgElement });
                await processor.enable();
            } finally {
            }
        }
    }
    const setBackgroundBlur = async () => {
        let processor = await getProcessorInstance();
        try {
            processor.setOptions({ type: 'blur', blurDegree: 2 });
            await processor.enable();
        } finally {
        }
    }

    //Todo:add one button and disbale background for teacher
    const disableBackgroundImage = async () => {
        let processor = await getProcessorInstance();
        try {
            await processor.disable();
        } finally {
        }
    }

    const setBackgroundColor = async () => {
        if (cameraTrack) {
            let processor = await getProcessorInstance();
            if (processor) {
                try {
                    processor.setOptions({ type: 'color', color: '#00ff00' });
                    await processor.enable();
                } finally {
                }
            }

        }
    }

    // Page pop up blocker checks
    const popupBlocker = () => {
        // var mine = window.open('', '', 'width=1,height=1,left=0,top=0,scrollbars=no');
        // if (mine)
        //     var popUpsBlocked = false
        // else {
        //     var popUpsBlocked = true
        //     alert('Please allow your pop-up blocker and refresh this page or start the call again from the dashboard.');
        //     //  Display Error Message
        // }
        // if(mine)
        // mine.close()
        detectWebcam()
    }
    // Camera or microphone blocker checks
    const detectWebcam = (callback) => {
        let constraints = { audio: true, video: true };
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
            })
            .catch((err) => {
                if (err.name == "NotAllowedError") {
                    alert('Camera or Microphone access permission denied. Please allow camera and microphone access for this site');
                } else if (err.name == 'NotReadableError') {
                    console.log('**********Navigator-error --- Camera connection failed. Please off the camera if already running on other apps');
                } else {
                    console.log('**********Navigator-error --- Camera connection failed. Please off the camera if already running on other apps');
                }
            });
    }

    const minimizeWin = () => {
        document.getElementById('user-sm-view').append(...document.getElementById('video-call-stream').childNodes);
        var elem = document.getElementById('user-sm-view').children[2];
        elem.className = "SmalViewContainer";
        setAddFastboard(true);
        // props.setLeaveScreenShare(false);
        setFastStatus(true);
        // alert('minimize screen');
    }

    // const openFastboardFun = () => {
    //     props.getFastboardToken();
    //     console.log("Fastbord====", props.fastboardtoken);
    //     setFastboardStatus(false);
    //     props.setLeaveScreenShare(false);
    //     minimizeWin();
    // }

    const resetFastboardWin = (user) => {
        setFastStatus(false);
        setAddFastboard(false);
        // console.log("fastStatus==", fastStatus);
        setTimeout(() => {
            try {
                document.getElementById('video-call-stream').appendChild(document.getElementById('user-sm-view').childNodes[2]);
                var elem = document.getElementById('video-call-stream').children[0];
                elem.className = "teacherVideo-container";
            } catch (error) {
                console.log("error", error);
            }
        }, 1000)
    }

    // const closefastboardFun = () => {
    //     props.setOpenFastboard(false);
    //     resetFastboardWin();
    //     setFastboardStatus(true);
    //     props.closeFastboard();
    //     props.setLeaveScreenShare(true);
    // }
    // const handleLeft = () => {
    //     document.getElementsByClassName('teacherVideo-containerMobileSingleView')[0];
    // }

    // Cureent user joins func
    const joinAndDisplayLocalStream = async () => {
        setUsers(preValue => _.uniqBy([...preValue, UID]));
        let arr = [...Users, UID];

        // AgoraRTC.createCameraVideoTrack().then(cameraTrack => {
        //     console.log('getCameras===============',cameraTrack);
        // }, e => {

        // });
        let deviceName;
        try {
            //commnt this line to disable iPad audio distortion fix
            deviceName = getAppleDeviceType();
        } catch (e) {
            Mixpanel.track(MIX_PANEL_CONST.SCRIPT_ERROR, e);
            console.log('script-error', e);
        }

        try {
            if (deviceName == "iPad") {
                AgoraRTC.setParameter("DISABLE_WEBAUDIO", true);
                microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
            } else {
                microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
            }
            Mixpanel.track(MIX_PANEL_CONST.MICROPHONE_SUCCESS, microphoneTrack);
        } catch (e) {
            if (e?.code == 'PERMISSION_DENIED') {
                Mixpanel.track(MIX_PANEL_CONST.MICROPHONE_PERMISSION_DENIED, e);
                console.log('**********Microphone access permission denied. Please allow microphone access for this site');
            } else if (e?.code == 'NOT_READABLE') {
                Mixpanel.track(MIX_PANEL_CONST.MICROPHONE_NOT_READABLE, e);
                alert('Microphone connection failed. Please off the microphone if already running on other apps');
            } else {
                Mixpanel.track(MIX_PANEL_CONST.MICROPHONE_ERROR, e);
                alert("Error while accessing Microphone");
            }
        }
        function getFamily() {
            var segments = /iPhone|iPad|Macintosh/.exec(navigator.userAgent);
            if (segments && segments.length > 0) {
                return segments[0];
            }
        }
        function getReportedRenderer() {
            var canvas = document.createElement("canvas");
            if (canvas != null) {
                var context = canvas.getContext("webgl") ||
                    canvas.getContext("experimental-webgl");
                if (context) {
                    var info = context.getExtension(
                        "WEBGL_debug_renderer_info");
                    if (info) {
                        return context.getParameter(
                            info.UNMASKED_RENDERER_WEBGL);
                    }
                }
            }
        }
        function getAppleDeviceType() {
            var family = getFamily();
            if (family == "Macintosh") {
                let renderer = getReportedRenderer();
                if (renderer.includes("Apple")) {
                    family = "iPad";
                }
                else if (renderer.includes("Intel")) {
                    family = "Macintosh";
                }
            }
            switch (family) {
                case "iPad": return "iPad";
                case "iPhone": return "iPhone";
                case "Macintosh": return "Desktop";
                default: return "Unknown";
            }
        }
        if (microphoneTrack) {
            let micId;
            let allMicrophones = await AgoraRTC.getMicrophones();
            console.log('allMicrophones', allMicrophones);
            //console.log('audioTracks',audioTracks);
            if (localStorage.getItem('activeAudioDevice')) {
                micId = allMicrophones.find(e => e.deviceId == localStorage.getItem('activeAudioDevice'))?.deviceId;
                micId && microphoneTrack.setDevice(micId);
            }
            if (!micId) {
                //micId = allMicrophones.find(e => e.label == audioTracks[0].label)?.deviceId;
                micId && microphoneTrack.setDevice(micId);
                micId && localStorage.setItem('activeAudioDevice', micId);
            }
            if (!micId) {
                micId = allMicrophones.find(e => e.label == microphoneTrack?._deviceName)?.deviceId;
                micId && localStorage.setItem('activeAudioDevice', micId);
            }
        }
        try {
            // Create a local video track from the video captured by a camera.
            // cameraTrack = await AgoraRTC.createCameraVideoTrack({
            //     encoderConfig:
            //     {
            //     width: 640,
            //     // Specify a value range and an ideal value
            //     height: { ideal: 360, min: 300, max: 400 },
            //     frameRate: 15,
            //     bitrateMin: 600, bitrateMax: 1000,
            //     },
            // });
            const vProfile = (videoCallProfileConfig[UID]) || "360p_1";
            cameraTrack = await AgoraRTC.createCameraVideoTrack({ encoderConfig: vProfile });
            Mixpanel.track(MIX_PANEL_CONST.CAMERA_SUCCESS, cameraTrack);
        } catch (e) {
            if (e?.code == 'PERMISSION_DENIED') {
                Mixpanel.track(MIX_PANEL_CONST.CAMERA_PERMISSION_DENIED, e);
                console.log('**********Camera access permission denied. Please allow camera access for this site');
            } else if (e?.code == 'NOT_READABLE') {
                Mixpanel.track(MIX_PANEL_CONST.CAMERA_NOT_READABLE, e);
                alert('Camera connection failed. Please off the camera if already running on other apps');
            } else {
                Mixpanel.track(MIX_PANEL_CONST.CAMERA_ERROR, e);
                alert("Error while accessing Camera");
            }
        }
        if (cameraTrack) {
            let cameraId;
            let allCameras = await AgoraRTC.getCameras();
            console.log('allCameras', allCameras);
            //console.log('videoTracks',videoTracks);
            if (localStorage.getItem('activeVideoDevice')) {
                cameraId = allCameras.find(e => e.deviceId == localStorage.getItem('activeVideoDevice'))?.deviceId;
                cameraId && cameraTrack.setDevice(cameraId);
            }
            if (!cameraId) {
                //cameraId = allCameras.find(e => e.label == videoTracks[0].label)?.deviceId;
                cameraId && cameraTrack.setDevice(cameraId);
                cameraId && localStorage.setItem('activeVideoDevice', cameraId);
            }
            if (!cameraId) {
                cameraId = allCameras.find(e => e.label == cameraTrack?._deviceName)?.deviceId;
                cameraId && localStorage.setItem('activeVideoDevice', cameraId);
            }
        }
        // })
        // .catch(function (error) {
        //     console.log(error);
        // });
        try {
            client.on('user-published', handleUserJoined)
            client.on("user-unpublished", handleUserUnJoined)
            client.on('user-left', handleUserLeft)
            client.on('user-joined', handleJoined)
            await client.join(APP_ID, CHANNEL, TOKEN, UID)
            let player;
            if (!_.endsWith(UID, '0000') && _.size(Users) < 2) {
                player = `<div class="${_.size(Users) < 2 ? "teacherVideo-containerMobileSingleView" : "teacherVideo-container"}" id="user-container-${UID}" >
                <div class="teacherVideo-player" id="user-${UID}">
                </div>
            </div>`
                document.getElementById('video-call-stream').insertAdjacentHTML('beforeend', player)
                cameraTrack.play(`user-${UID}`)
            } else {
                player = `<div class="SmalViewContainer" id="user-container-${UID}">
                <div class="teacherVideo-player" id="user-${UID}">
                </div>
                </div>`
                document.getElementById('user-sm-view').insertAdjacentHTML('beforeend', player)
                cameraTrack.play(`user-${UID}`)
            }
            await client.publish([microphoneTrack, cameraTrack])
            countAll = document.querySelectorAll('#user-sm-view .SmalViewContainer').length;
            Mixpanel.track(MIX_PANEL_CONST.VIDEO_CALL_STARTED, { ...{username : username}, ...appdetails });
        } catch (e) {
            Mixpanel.track(MIX_PANEL_CONST.SCRIPT_ERROR, e);
            console.log('script-error', e);
        }

    }

    const handleJoined = async (user) => {
        if (user.uid != UID) {
            props.handleWaitScreen(false);
            setUserJoined(true);
        }
        if (role == "teacher" && user.uid.toString().endsWith("0000") && user.uid.toString().indexOf(UID) < 0) {
            setTriggerSubscripe(false);
        }
        
    }

    // New user join function
    const handleUserJoined = async (user, mediaType) => {
        try {
            console.log('joineddd===', user.uid);
            // user.uid=''+user.uid;
            setUsers(preValue => _.uniqBy([...preValue, user.uid]));
            let arr = [UID, user.uid]
            remoteUsers[user.uid] = user
            // if(!_.startsWith( user.uid, '3953')){
            props.iamWeb();
            if (!(_.endsWith(user.uid.toString(), '0000') && user.uid.toString().replace('0000', '') == UID)) {
                await client.subscribe(user, mediaType);
            }
            if (mediaType === 'video') {
                let player = document.getElementById(`user-container-${user.uid}`)
                if (player != null) {
                    player.remove()
                }
                if (_.endsWith(user.uid.toString(), '0000') && _.includes(user.uid.toString(), query)) {
                    player = `<div class="teacherVideo-container" id="user-container-${user.uid}">
                     <i class="google-material-icons Hdh4hc cIGbvc" aria-hidden="true">present_to_all</i>
                     <div class="share-player" >
                     You're presenting to everyone
                                     </div>
                      </div>`

                    document.getElementById('user-sm-view').append(...document.getElementById('video-call-stream').childNodes);
                    var elem = document.getElementById('user-sm-view').children[2];
                    elem.className = "SmalViewContainer"
                    document.getElementById('video-call-stream').insertAdjacentHTML('beforeend', player)

                } else if (_.endsWith(user.uid.toString(), '0000') && !_.includes(user.uid.toString(), query)) {
                    // setAddFastboard(true);
                    player = `<div class=" teacherVideo-container" id="user-container-${user.uid}">
                     <div class="teacherVideo-player" id="user-${user.uid}">
                     </div>
                 </div>`
                    document.getElementById('user-sm-view').append(...document.getElementById('video-call-stream').childNodes);
                    var elem = document.getElementById('user-sm-view').children[2];
                    elem.className = "SmalViewContainer"
                    document.getElementById('video-call-stream').insertAdjacentHTML('beforeend', player)
                    user.videoTrack.play(`user-${user.uid}`)
                } else {
                    player = `<div class="${_.size(arr) >= 2 ? 'teacherVideo-container' : 'teacherVideo-containerMobileSingleView'}" id="user-container-${user.uid}" >
                                 <div class="teacherVideo-player" id="user-${user.uid}">
                                 </div>
                             </div>`
                    // setAddFastboard(true);
                    if (document.getElementById('video-call-stream')) {
                        document.getElementById('user-sm-view').append(...document.getElementById('video-call-stream').childNodes);
                        var elem = document.getElementById('user-sm-view').children[1];
                        elem.className = "SmalViewContainer"
                        document.getElementById('video-call-stream').insertAdjacentHTML('beforeend', player)
                    } else {
                        player = `<div class="SmalViewContainer" id="user-container-${user.uid}" >
                        <div class="teacherVideo-player" id="user-${user.uid}">
                        </div>
                    </div>`
                        document.getElementById('user-sm-view').insertAdjacentHTML('beforeend', player)
                    }
                    user.videoTrack.play(`user-${user.uid}`)
                }
            }
            if (mediaType === 'audio') {
                user.audioTrack.play()
            }
            countAll = document.querySelectorAll('#user-sm-view .SmalViewContainer').length;
            if (!fastboardstatus) {
                alert('hiii');
                setTimeout(() => {
                    minimizeWin();
                }, 2000)

            }
        } catch (e) {
            Mixpanel.track(MIX_PANEL_CONST.SCRIPT_ERROR, e);
            console.log('script-error', e);
        }
    }

    // User unpublish
    const handleUserUnJoined = async (user, mediaType) => {

        if (mediaType === "audio") {
            user.audioTrack?.stop();
        }
        if (mediaType === "video") {
            setUsers((prevUsers) => {
                return prevUsers.filter((User) => User.uid !== user.uid);
            });
        }
    }
    // User left the stream
    const handleUserLeft = async (user) => {
        if (role == "teacher" && user.uid.toString().endsWith("0000") && user.uid.toString().indexOf(UID) < 0) {
            setTriggerSubscripe(true);
        }
        if (!user.uid.toString().endsWith("0000")) {
            props.setUserLeftStatus(false);
        }
        if (role !== "teacher") {
            props.endFastboard(false);
        }
        resetFastboardWin();
        props.setOpenFastboard(false);
        setFastboardStatus(true);
        props.setLeaveScreenShare(true);
        // closefastboardFun();
        delete remoteUsers[user.uid];
        document.getElementById('video-call-stream').appendChild(document.getElementById('user-sm-view').childNodes[2]);
        var elem = document.getElementById('video-call-stream').children[1];
        elem.className = "teacherVideo-container"
        { user.uid && document.getElementById(`user-container-${user.uid}`).remove() }
        // setUsers((prevUsers) => {
        //     return prevUsers.filter((User) => User !== user.uid);
        // });
        setUsers((prevUsers) => {
            return prevUsers.filter((User) => User !== user.uid);
        });
    }

    const toggle = (e) => {
        setIsOpened(wasOpened => !wasOpened);
        var elem = document.getElementById('video-call-stream').children[0];
        if (e == true) {
            elem.className = 'teacherVideo-containerMobileSingleView'
        } else {
            elem.className = 'teacherVideo-container'

        }

    }

    const render = () => {
        return (
            <div>
                <div className="backRoundImg" id="video-stream">
                    <ReactInternetSpeedMeter
                        txtSubHeading="Internet connection is slow"
                        outputType="" // "alert"/"modal"/"empty"
                        customClassName={null}
                        pingInterval={2000} // milliseconds
                        txtMainHeading="Opps..."
                        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                        threshold={50}
                        imageUrl="https://i.postimg.cc/sft772VP/speedometer.png"
                        downloadSize="1561257" //bytes
                        callbackFunctionOnNetworkDown={(data) => { }
                        }
                        callbackFunctionOnNetworkTest={(data) => setWifiStrength(data)}
                    />
                    <div className='logoImg'>
                        {/* <div style={{'color':'green'}}>{wifiStrength} Mbps</div> */}
                        <img src={require("../../assets/img/favicon.ico")} width="50" height="50" />
                        {/* <LeaveStream /> */}
                        {isOnline && wifiStrength > 25 && <span title='EXCELLENT'><WifiIndicator strength='EXCELLENT' /></span>}
                        {isOnline && wifiStrength < 25 && wifiStrength > 10 && <span title='GOOD'><WifiIndicator strength='GREAT' /></span>}
                        {isOnline && wifiStrength < 10 && wifiStrength > 4 && <span title='SLOW'><WifiIndicator strength='OKAY' /></span>}
                        {isOnline && wifiStrength < 4 && wifiStrength > 1 && <span title='POOR'><WifiIndicator strength='WEAK' /></span>}
                        {isOnline && wifiStrength < 1 && wifiStrength >= 0 && <span title='WEAK'><WifiIndicator strength='UNUSABLE' /></span>}
                        {!isOnline && <span title='DISCONNECTED'><WifiIndicator strength='DISCONNECTED' /></span>}
                    </div>

                    <div className="parentDiv">
                        <div className='LargeView'>
                            {
                                fastStatus == true ?
                                    <>
                                        {
                                            props.openFastboard == true ?
                                                <div className="video-call-container" onClick={() => {
                                                    let ele = document.getElementsByClassName('netless-window-manager-wrapper')[0];
                                                    let ele1 = document.getElementsByClassName('fastboard-react-div')[0];
                                                    ele1.style.background = "#f4f4f4";
                                                    ele.style.background = "white";
                                                }}
                                                    style={minFast == true ? { background: '#f4f4f4', width: '72%' } : { background: '#f4f4f4', width: '90%' }}
                                                    id="video-call-fastboard">
                                                    <CreateFastboard {...props} query={query} />
                                                </div>
                                                : null
                                        }
                                    </>
                                    :
                                    <div className="video-call-container" id="video-call-stream">
                                    </div>
                            }
                        </div>
                        <div className={` ${isOpened == true ? 'd-none' : "d-block"} `}>
                            {_.size(Users) > 1 && <div className={`${(_.size(Users) < 3 || !_.isEmpty(screensharestatus)) && !addfastboard ? "SmallViewsingleVideo" : 'SmallView'}`} id="user-sm-view">
                                <div className={`Minimize ${isOpened == true ? 'd-none' : "d-block"} `} onClick={() => {
                                    setMinFast(false);
                                    toggle(true)
                                }}>
                                    <i class="fa fa-window-minimize" aria-hidden="true"></i>
                                </div>

                            </div>}
                        </div>
                        <div className={` ${isOpened == false ? 'd-none' : "dblock"} `}>
                            <div className={`bottomMaxbtn ${isOpened == false ? 'd-none' : "d-block"} `} onClick={() => {
                                setMinFast(true);
                                toggle(false)
                            }}>
                                <div className='participantGroup'>
                                    <i class="fa fa-users" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ButtonScreen userJoined={userJoined} triggerSubscripe={triggerSubscripe} {...props} {...appdetails} settingModalStatus={settingModalStatus} setSettingModalStatus={setSettingModalStatus} setScreensharestatus={setScreensharestatus} Users={Users} setBackgroundstatus={setBackgroundstatus} backgroundStatus={backgroundStatus} minimizeWin={minimizeWin} resetFastboardWin={resetFastboardWin} setFastStatus={setFastStatus} setFastboardStatus={setFastboardStatus}
                        fastboardstatus={fastboardstatus}
                    //  openFastboardFun={openFastboardFun} closefastboardFun={closefastboardFun}
                    />
                    {
                        settingModalStatus == true ?
                            <SettingsView {...props}
                                backgroundStatus={backgroundStatus}
                                setBackgroundstatus={setBackgroundstatus}
                                handleClose={() => setSettingModalStatus(false)} /> : null
                    }
                </div>
            </div >
        )
    }
    return render();
}

export {
    client,
    cameraTrack,
    microphoneTrack
}
export default VideoScreen;
