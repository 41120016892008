import React, { useEffect, useRef } from 'react';
import { microphoneTrack, cameraTrack, client } from '../../video-screen/index';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import RecordScreen from './record-func';
import { MIX_PANEL_CONST } from '../../../constants/MixpanelConstants';
import { Mixpanel } from '../../../utils/Mixpanel';
import call_end from '../../../assets/img/icon/call_end.svg';

const LeaveStream = (props) => {
    let searchParams = new URLSearchParams(window.location.search);
    const cname = searchParams.get('cname');
    const query = searchParams.get('id');
    const role = searchParams.get('role');
    const username = searchParams.get('username');
    const componentOneRef = useRef(null);
    const componentOne = <RecordScreen ref={ componentOneRef } />;

    const leaveAndRemoveLocalStream = async (e) => {
        if (window.confirm("Are you sure to close this session.")) {
            props.setLeaveCallStatus(true);
            setTimeout(async() => {
                if (_.size(microphoneTrack) > 0) {
                    microphoneTrack.stop()
                    microphoneTrack.close()
                }
                if (_.size(cameraTrack) > 0) {
                    cameraTrack.stop()
                    cameraTrack.close()
                }
                await client.leave();
                localStorage.clear();
                Mixpanel.track(MIX_PANEL_CONST.CLOSE_BUTTON_CLICKED, {username : username, role:role, classid : cname});
                document.getElementsByTagName ('html') [0] .remove ();    
                client.removeAllListeners()
                closeTab();
            },1000)
        }    
    }
    
    const closeTab = () => { 
        window.opener = null;
    window.open('', '_self');
    window.close();
      };
    return (
        <React.Fragment>
         {/* <div className="close-btn" onClick={leaveAndRemoveLocalStream}>
                    <i className="fa-solid fa-xmark"></i>
                    <span> Close</span>
                </div> */}
                <div className="links" title='Leave call' onClick={leaveAndRemoveLocalStream}>
                            <div className="text-center icon-holder-primary">
                                <img className='icon-img' src={call_end} />
                                {/* <div className="linkContent hide">Stop Screen</div> */}
                            </div>
                        </div>
        </React.Fragment>
       
    )
}
export default LeaveStream;