import React, { useState } from 'react';
import AudioSetting from './audio-setting';
import VideoSetting from './video-setting';
import BackgroundSetting from './background-setting';
import './style.css';
import audioIcon from '../../assets/img/icon/speaker.svg';
import videoIcon from '../../assets/img/icon/videocam_black.svg';
import bgIcon from '../../assets/img/icon/blur_on.svg';

const SettingsView = (props) => {
    let searchParams = new URLSearchParams(window.location.search);
    const role = searchParams.get('role');
    const [activeMenu, setActiveMenu] = useState('audio');

    // const toggleChat = () => {
    //     document.getElementsByClassName('rcw-launcher')[0].click();
    //     setChatStatus(!chatStatus)
    // }
    
    return (
        <div className="setting-mask">
            <div className='setting-wrapper'>
                <div className="setting-close" onClick={props.handleClose} >
                <i style={{'color': 'black','fontSize': '24px'}} className="fa-solid fa-xmark"></i>
                </div>
                <div className='setting-container'>
                    <div className='setting-left'>
                       <h4 className='settings-tit'>Settings</h4>
                       <div className={activeMenu == 'audio' ? 'setting-menu-item setting-active' : 'setting-menu-item'} onClick={() => setActiveMenu('audio')}><img className='setting-menu-icon' src={audioIcon} /> Audio</div>
                       <div className={activeMenu == 'video' ? 'setting-menu-item setting-active' : 'setting-menu-item'} onClick={() => setActiveMenu('video')}><img className='setting-menu-icon' src={videoIcon} /> Video</div>
                       {role == "teacher" && <div className={activeMenu == 'background' ? 'setting-menu-item setting-active' : 'setting-menu-item'} onClick={() => setActiveMenu('background')}><img className='setting-menu-icon' src={bgIcon} /> Background</div>}
                    </div>
                    <div className='setting-right'>
                    <h4 style={{'visibility': 'hidden'}} className='settings-tit'>Settings</h4>
                    {activeMenu == 'audio' && <AudioSetting />}
                    {activeMenu == 'video' && <VideoSetting />}
                    {activeMenu == 'background' && <BackgroundSetting {...props}/>}
                    </div>
                </div>              
            </div>
        </div>
    )
}
export default SettingsView;