import React, { Component, useEffect, useState } from 'react';
import { render } from 'react-dom';
import Modal from 'react-modal';
import { cameraTrack, microphoneTrack } from '../video-screen/index';
import AgoraRTC from "agora-rtc-sdk-ng";
import './style.css';
const  AudioSetting = (props) => {
  console.log('modal11', props.modalstatus);
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const [allDevices, setDevices] = useState([]);
  const closeModal = () => {
    setmodalIsOpen(!modalIsOpen);
   // props.setDeviceModal(false)
  };


  useEffect(() => {
    console.log('====')
    setmodalIsOpen(props.modalstatus)
  }, [props.modalstatus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // An object specifying the types of media to request.
        var constraints = window.constraints = { audio: true, video: true};
        // A method to request media stream object.
        await navigator.mediaDevices.getUserMedia(constraints)
        .then(function(stream) {
        // Get all the available video tracks.
        var audioTracks = stream.getAudioTracks();
        console.log('Using video device: ' + audioTracks[0].label);
        console.log('stream=====',stream);
        })
        .catch(function(error) {
        console.log(error);
        });

        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        /* use the stream */
        displayDeviceLabels();
      } catch (err) {
        /* handle the error */
      }
    }
    // call the function
    //fetchData()
    displayDeviceLabels();
  }, []);

  const displayDeviceLabels = () => {
    navigator.mediaDevices.enumerateDevices().then(async (devices) => {
      console.log('devices', devices)
      setDevices(devices);
      if(localStorage.getItem('activeAudioDevice')){
        document.getElementById('audio').value = localStorage.getItem('activeAudioDevice');
      }
      if(devices.find(e => e.label == microphoneTrack._deviceName)?.deviceId){
        //console.log('microphoneTrack===',microphoneTrack);
        document.getElementById('audio').value = devices.find(e => e.label == microphoneTrack._deviceName)?.deviceId
      }
    });
  };

  const setCustomDevice = (ev, type) => {
    console.log('deviceId=', ev.target.value)
    closeModal();
    if (type == 'audio') {
      localStorage.setItem('activeAudioDevice', ev.target.value)
      microphoneTrack.setDevice(ev.target.value);
      // microphoneTrack = AgoraRTC.createCustomAudioTrack({
      //   mediaStreamTrack: ev.target.value,
      // });
    }

  }
  return (
    <div>

        <div style={{'padding': '0px 25px'}}>
          <div className='audio-lable' style={{'color':'#E82C86'}}>Microphone</div>

          <select className="form-control audio-select" name="audio" id="audio" onChange={(e) => setCustomDevice(e, 'audio')}>
            {allDevices.filter(rowData => rowData.kind == 'audioinput').map((item, index) => (
              <option value={item.deviceId} key={index}>{item.label}</option>
            ))
            }
          </select>
        </div>

    </div>

  );
}


export default AudioSetting;