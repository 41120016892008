import React, { useState } from 'react';
import {client,
    cameraTrack } from '../index';
    import camon from '../../../assets/img/icon/videocam.svg';
    import camoff from '../../../assets/img/icon/videocam_off.svg';
    import { MIX_PANEL_CONST } from '../../../constants/MixpanelConstants';
    import { Mixpanel } from '../../../utils/Mixpanel';
    import '../style.css';
const CamScreen =(props) => {
const [camstatus, setcamstatus ] = useState();

const toggleCamera = async (e) => {
    let searchParams = new URLSearchParams(window.location.search);
    const cname = searchParams.get('cname');
    const query = searchParams.get('id');
    const role = searchParams.get('role');
    const username = searchParams.get('username');
    if (cameraTrack.muted) {
        await cameraTrack.setMuted(false);
        Mixpanel.track(MIX_PANEL_CONST.CAMERA_OFF, {username: username, role : role, channelName : cname, id : query});
        props.setCameraStatus(true)
        setcamstatus(true)
    } else {
        await cameraTrack.setMuted(true);
        Mixpanel.track(MIX_PANEL_CONST.CAMERA_ON, {username: username, role : role, channelName : cname, id : query});
        props.setCameraStatus(false);
        setcamstatus(false)
    }
}
    return (
        <div>
            {(()=>{
                if(camstatus===false) {
                    return(
                        <div className="links" title='Turn on camera' onClick={toggleCamera}>
                        <div className="text-center icon-holder-primary">
                        {/* <i class="fa fa-video-slash"></i> */}
                        <img className='icon-img' src={camoff} />
                        {/* <div className="linkContent hide">Cam off</div> */}
                        </div>
                    </div>
                    )
                    
                }else{
                    return(
                        <div className="links" title='Turn off camera' onClick={toggleCamera}>
                        <div className="text-center icon-holder-secondary">
                            {/* <i class="fa fa-video-camera" ></i> */}
                            <img className='icon-img' src={camon} />
                            {/* <div className="linkContent hide">Cam on</div> */}
                        </div>
                    </div> 
                    )
                  
                }
            })()}
       
            </div>
    )
}
export default CamScreen;