import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import _ from 'lodash';
import { Button, Tooltip, message, Popconfirm } from 'antd';
import LeaveStream from './leave-func';
import { MIX_PANEL_CONST } from '../../../constants/MixpanelConstants';
import { Mixpanel } from '../../../utils/Mixpanel';
import icon_record_stop from '../../../assets/img/icon/recording_stop.svg';
import icon_record from '../../../assets/img/icon/recording.svg';
import { green, red } from '@mui/material/colors';

const RecordScreen = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        stopRecord() {
            fetch(`${process.env.REACT_APP_BASE_URL}/stoprecording/${CHANNEL}/${UID}/${_.get(recorddetails, 'resourceId')}/${_.get(recorddetails, 'sid')}`)
                .then(data => {
                    return data.json();
                })
                .then(startdata => {
                    setrecordstatus(true)
                },
                    err => {
                        console.log('stop err', err);
                    }
                );
        }
    }));
    let searchParams = new URLSearchParams(window.location.search);

    const [recordstatus, setrecordstatus] = useState(true);
    const [recorddetails, setrecorddetails] = useState(null);
    const CHANNEL = _.get(props, 'channelName');
    const teacherId = searchParams.get('id');
    const role = searchParams.get('role');
    const username = searchParams.get('username');
    const cname = searchParams.get('cname');
    const UID = `98761`;
    let TOKEN;
    const [apiToken, setApiToken] = useState(null);
    const [popupstatus, setpopupstatus] = useState(true);
    const [disabled, setdisabled] = useState(false);
    const localStorageRecording='recording';
    useEffect(() => {
        //props.setUpdateRecordFun(updateRecord);
        props.updateRecording(updateRecord);
    }, [])
    useEffect(() => {
        var details = JSON.parse(localStorage.getItem(localStorageRecording));
        if (_.isEqual(_.get(details, 'channel'), CHANNEL)) {
            setrecorddetails(details);
            queryRecord(details);
            
        }

    }, [CHANNEL])
    useEffect(() => {
        if(props.userJoined && recordstatus){
            tokenapicall();
            Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_CLICK, {username: username, role : role, channelName : cname, id : teacherId})
        }   
    }, [props.userJoined])
    useEffect(() => {
        if(props.leaveCallStatus && !recordstatus){
            stopRecord();
            Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_STOP_CLICKED, {username: username, role : role, channelName : cname, id : teacherId})
        }   
    }, [props.leaveCallStatus])
    
    const tokenapicall = () => {
        setdisabled(true);
        fetch(`${process.env.REACT_APP_BASE_URL}/generatetoken/${CHANNEL}/publisher/uid/${UID}`)
            .then(data => {
                return data.json();
            })
            .then(post => {
                TOKEN = _.get(post, 'token');
                if (TOKEN) {
                    acquireRecord();
                }
                else {
                    setdisabled(false);
                }
            });
    }

    const acquireRecord = async (e) => {
        Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_CLICK);
        await fetch(`${process.env.REACT_APP_BASE_URL}/acquire/${CHANNEL}/${UID}`)
            .then(data => {
                return data.json();
            })
            .then(post => {
                Mixpanel.track("Acquire Recording API Success", {response: post});
                startRecord(post, e);
            })
            .catch((error) => {
                Mixpanel.track("Acquire Recording API Error", {...error});

            });
    }

    const startRecord = (post, e) => {
        const resourceId = _.get(post, 'resourceId');
        const data = { 'token': TOKEN, 'channel': CHANNEL, 'uid': UID, 'resourceId': resourceId, 'teacherId': teacherId };
        fetch(`${process.env.REACT_APP_BASE_URL}/startrecording`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(data => {
                return data.json();
            })
            .then(startdata => {
                const code=_.get(startdata, 'code');
                if (!_.isEmpty(_.get(startdata, 'sid'))) {
                    props.setRecortingStatus(true);
                    Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_STARTED);
                    localStorage.setItem(localStorageRecording, JSON.stringify({ ...startdata, 'channel': CHANNEL }));
                    setrecordstatus(false);
                    setpopupstatus(false);
                    setrecorddetails(startdata);
                    queryRecord(startdata);
                    setdisabled(false);
                }
                else if (code == 7 || code == 53) {//Added error code from this documennt https://docs.agora.io/en/cloud-recording/common_errors?platform=RESTful
                    props.setRecortingStatus(true);
                    Mixpanel.track("Recording Already Running");
                    setrecordstatus(false);
                    setpopupstatus(false);
                    setdisabled(false);
                    setrecorddetails(JSON.parse(localStorage.getItem(localStorageRecording)));
                }
            })
            .catch((error) => {
                // Your error is here!
                props.setRecortingStatus(false);
                Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_FAILURE);

            });
    }

    const queryRecord = (recorddetails) => {
        Mixpanel.track("Query Recording API Called", {params: recorddetails});
        fetch(`${process.env.REACT_APP_BASE_URL}/queryrecording/${CHANNEL}/${UID}/${_.get(recorddetails, 'resourceId')}/${_.get(recorddetails, 'sid')}`)
            .then(data => {
                return data.json();

            })
            .then(startdata => {
                Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_QUERY_SUCCESS, {response:startdata});
                if (startdata.code == 404) {
                    localStorage.removeItem(localStorageRecording);
                    setrecordstatus(true);
                    props.setRecortingStatus(false);
                    setpopupstatus(true);
                    setrecorddetails(null);
                    setdisabled(false);
                }else if(startdata?.serverResponse?.fileList?.length){
                    setrecordstatus(false);
                    setpopupstatus(false);
                    props.setRecortingStatus(true);
                    updateRecord(true, props.Users , () => {});
                }
            },
                err => {
                    Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_QUERY_FAILURE, {error:err});
                    localStorage.removeItem(localStorageRecording);
                    setrecordstatus(true);
                    props.setRecortingStatus(false);
                    setpopupstatus(true);
                    setrecorddetails(null);
                    setdisabled(false);
                }
            );
    }

    const stopRecord = (e) => {
        setdisabled(true);
        fetch(`${process.env.REACT_APP_BASE_URL}/stoprecording/${CHANNEL}/${UID}/${_.get(recorddetails, 'resourceId')}/${_.get(recorddetails, 'sid')}`)
            .then(data => {
                return data.json();
            })
            .then(startdata => {
                setdisabled(false);
                setrecordstatus(true);
                props.setRecortingStatus(false);
                setpopupstatus(true);
                localStorage.removeItem(localStorageRecording);
                Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_STOP_SUCCESS, { fileName: _.get(startdata, 'serverResponse.fileList'), apiResponse:startdata });
                if (startdata.code == 500) {
                    Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_STOP_FAILURE, {apiResponse:startdata});
                }
            },
                err => {
                    setdisabled(false);
                    setrecordstatus(true);
                    props.setRecortingStatus(false);
                    setpopupstatus(true);
                    Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_STOP_FAILURE, {apiResponse: err});
                    localStorage.removeItem(localStorageRecording);
                }
            );
    }

    const cancel = (e) => {
        message.error('Click on No');
    };
    const updateRecord = (subscripe, users , cb) => {
        if(subscripe){
            Mixpanel.track(MIX_PANEL_CONST.SUBSCRIPE_RECORDING, {...users});
        }else{
            Mixpanel.track(MIX_PANEL_CONST.UNSUBSCRIPE_RECORDING, {...users});
        }
        if(JSON.parse(localStorage.recording)){
            const data = {subscripe : subscripe, users: users, 'channel': CHANNEL, 'uid': UID, 'resourceId': JSON.parse(localStorage.recording).resourceId, 'teacherId': teacherId, sid: JSON.parse(localStorage.recording).sid };
            fetch(`${process.env.REACT_APP_BASE_URL}/updaterecording`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(data => {
                    return data.json();
                })
                .then(startdata => {
                    Mixpanel.track(MIX_PANEL_CONST.SUBSCRIPE_API_RESPONSE, {...startdata});
                    cb(startdata);
                })
                .catch((error) => {
                    Mixpanel.track(MIX_PANEL_CONST.SUBSCRIPE_API_RESPONSE, {...error});
                    cb(error);
                    // Your error is here!
                });
        }
    }
    
    return (
        <div>
            <div>
                {recordstatus && <div className="recording-popup">
                        <div className="ant-popover-message"><span role="img" aria-label="exclamation-circle" className="anticon anticon-exclamation-circle"><svg viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
                        </svg></span>
                            <div className="ant-popover-message-title">Press ok or record button below to start the recording </div>
                        </div>
                        <div className="ant-popover-buttons">
                            <button type="button" style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}} className="ant-btn ant-btn-primary ant-btn-sm" onClick={() => { tokenapicall(); Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_CLICK) }}>
                                <span>Ok</span></button></div>
                 </div>}
            </div>
            {(() => {
                if (recordstatus === false) {
                    return (
                        <div className="links" title='Recording in progress' style={{ pointerEvents: "none", opacity: "0.7" }}>
                            <div className="text-center icon-holder-primary">
                            <img className='icon-img' src={icon_record_stop} />
                                {/* <i className="fa-regular fa-circle-stop"></i>
                                <div className="linkContent hide">Stop</div> */}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className='popup-record'
                            style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            {/* <Popconfirm
                            title="Press ok or record button below to start the recording "
                            onConfirm={() => { tokenapicall(); Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_CLICK) }}
                            onCancel={cancel}
                            okText="Ok"
                            showCancel={false}
                            defaultVisible={popupstatus}
                            style={{ width: '300px' }}
                        > */}
                            {/* <div className="ant-popover ant-popconfirm ant-popover-placement-top" >
                                <div className="ant-popover-content">
                                    <div className="ant-popover-arrow"><span className="ant-popover-arrow-content"></span></div>
                                    <div className="ant-popover-inner" role="tooltip">
                                        <div className="ant-popover-inner-content">
                                            <div className="ant-popover-message"><span role="img" aria-label="exclamation-circle" className="anticon anticon-exclamation-circle"><svg viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
                                            </svg></span>
                                                <div className="ant-popover-message-title">Press ok or record button below to start the recording </div>
                                            </div>
                                            <div className="ant-popover-buttons">
                                                <button type="button" className="ant-btn ant-btn-primary ant-btn-sm" onClick={() => { tokenapicall(); Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_CLICK) }}>
                                                    <span>Ok</span></button></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="links" title='Start recording'  style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}} onClick={() => { tokenapicall(); Mixpanel.track(MIX_PANEL_CONST.SCREEN_RECORD_CLICK); }}>

                                <div className="text-center icon-holder-secondary">
                                <img className='icon-img' src={icon_record} />
                                    {/* <i className="fa-solid fa-record-vinyl"></i>
                                    <div className="linkContent hide">Record</div> */}
                                </div>
                            </div>

                            {/* </Popconfirm> */}
                        </div>
                    )
                }
            })()}      </div>
    )
});
export default RecordScreen;
