export const MIX_PANEL_CONST = {
    // ENV DETAILS

    VIDEO_CALL_REQUEST_RECEIVED: 'Video Call Request Received',
    VIDEO_CALL_STARTED: 'Video Call Started',
    VIDEO_CALL_FAILURE: 'Video Call Failure',
    MUTE_CLICKED: 'Video Call Mute Clicked',
    UNMUTE_CLICKED: 'Video Call UnMute Clicked',
    CLOSE_BUTTON_CLICKED: 'Video Call Close Button Clicked',
    CAMERA_ON: 'Video Call Camera On',
    CAMERA_OFF: 'Video Call Camera Off',
    SCREEN_SHARE_CLICKED: 'Video Call Screen Share Clicked',
    SCREEN_SHARE_SUCCESS: 'Video Call Screen Share Success',
    SCREEN_SHARE_FAILURE: 'Video Call Screen Share Failure',
    SCREEN_SHARE_STOP_CLICKED:'Video Call Screen Share Stop Clicked',
    SCREEN_SHARE_STOP_SUCCESS: 'Video Call Screen Share Stop Success',
    SCREEN_SHARE_STOP_FAILURE: 'Video Call Screen Share Stop Failure',
    FULL_SCREEN_CLICKED: 'Video Call Full Screen Clicked',
    FULL_SCREEN_ON: 'Video Call Full Screen On',
    FULL_SCREEN_OFF: 'Video Call Full Screen Off',
    SCREEN_MINIMIZE_CLICKED: 'Video Call Screen Minimize clicked',
    SCREEN_MINIMIZE_STARTED: 'Video Call Screen Minimize Started',
    SCREEN_MINIMIZE_ENDED: 'Video Call Screen Minimize Ended',
    SCREEN_RECORD_CLICK: 'Video Call Screen Record Clicked',
    SCREEN_RECORD_STARTED: 'Video Call Screen Record Started',
    SCREEN_RECORD_START_FAILURE: 'Video Call Screen Record Failure',
    SCREEN_RECORD_QUERY_SUCCESS: 'Video Call Screen Record Query Success',
    SCREEN_RECORD_QUERY_FAILURE: 'Video Call Screen Record Query Failure',
    SCREEN_RECORD_STOP_CLICKED:'Video Call Screen Record Stop Clicked',
    SCREEN_RECORD_STOP_SUCCESS: 'Video Call Screen Record Top Success',
    SCREEN_RECORD_STOP_FAILURE: 'Video Call Screen Record Stop Failure',
    TOKEN_API_CALL_START: 'Video Call Token API Call Start',
    TOKEN_API_CALL_SUCCESS: 'Video Call Token API Call Success',
    TOKEN_API_CALL_ERROR: 'Video Call Token API Call Error',
    MICROPHONE_SUCCESS:'Video Call Microphone Access Success',
    CAMERA_SUCCESS:'Video Call Camera Access Success',
    MICROPHONE_PERMISSION_DENIED:'Video Call Microphone Permission Denied',
    MICROPHONE_NOT_READABLE:'Video Call Microphone Not Readable',
    MICROPHONE_ERROR:'Video Call Microphone Error',
    CAMERA_PERMISSION_DENIED:'Video Call Camera Permission Denied',
    CAMERA_NOT_READABLE:'Video Call Camera Not Readable',
    CAMERA_ERROR:'Video Call Camera Error',
    SCRIPT_ERROR:'Video Call Script Error',
    SUBSCRIPE_RECORDING: 'Video Call Subscripe Recording',
    UNSUBSCRIPE_RECORDING: 'Video Call Unsubscripe Recording',
    SUBSCRIPE_API_RESPONSE: 'Video Call Subscripe API Response'
};
