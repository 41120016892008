import React, { useState } from 'react';
import { useEffect } from 'react';
import chatIcon from '../../../../src/assets/img/icon/chat_bubble.svg';

const ChatButton = (props) => {
    const [chatStatus, setChatStatus] = useState();
    const [chatNotify, setChatNotify] = useState(0);

    const toggleChat = () => {
        document.getElementsByClassName('rcw-launcher')[0].click();
        setChatNotify(0);
        setChatStatus(!chatStatus)
    }

    useEffect(() => {
        if(props.messages.length){
            setChatNotify(chatNotify + 1);
        }
    }, [props.messages]);
    
    return (
        <div>
            {(() => {
                if (chatStatus == true) {
                    return (
                        <div className="links" title='Close chat' onClick={toggleChat}>
                            <div className="text-center icon-holder-primary">
                            <img  className='icon-img' src={chatIcon} />
                                {/* <div className="linkContent hide">Exit screen</div> */}
                            </div>
                        </div>
                    )

                } else {
                    return (
                        <div className="links" title='Open chat' onClick={toggleChat}>
                            <div className="text-center icon-holder-secondary">
                                {/* <i class="fa fa-expand" ></i> */}
                                <img  className='icon-img' src={chatIcon} />
                                {chatNotify ? <span className="chat-badge">{chatNotify}</span> : null}
                                {/* <div className="linkContent hide">Full screen</div> */}
                            </div>
                        </div>
                    )

                }
            })()}

        </div>
    )
}
export default ChatButton;