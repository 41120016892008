import React, { useState } from 'react';
import micon from '../../../assets/img/icon/mic.svg';
import micoff from '../../../assets/img/icon/mic_off.svg';
import {client,
    microphoneTrack } from '../index';
    import { MIX_PANEL_CONST } from '../../../constants/MixpanelConstants';
import { Mixpanel } from '../../../utils/Mixpanel';
const MicScreen =(props) => {
    let searchParams = new URLSearchParams(window.location.search);
    const cname = searchParams.get('cname');
    const query = searchParams.get('id');
    const role = searchParams.get('role');
    const username = searchParams.get('username');

    const [ micstatus , setmicstatus ] = useState();

    const toggleMic = async (e) => {
        if (microphoneTrack.muted) {
            await microphoneTrack.setMuted(false)
            Mixpanel.track(MIX_PANEL_CONST.UNMUTE_CLICKED, {username: username, role : role, channelName : cname, id : query});

            setmicstatus(true)
        } else {
            await microphoneTrack.setMuted(true)
            Mixpanel.track(MIX_PANEL_CONST.MUTE_CLICKED, {username: username, role : role, channelName : cname, id : query});

            setmicstatus(false)
        }
    }
    return (
        <div>
            {(()=>{
                if(micstatus===false){
                    return(
                        <div className="links" title='Turn on microphone' onClick={toggleMic}>
                        <div className="text-center icon-holder-primary">
                            <img className='icon-img' src={micoff} />
                            {/* <div className="linkContent hide">Mic off</div> */}
                        </div>
                    </div> 
                    )
                }else{
                    return(
                        <div className="links" title='Turn off microphone' onClick={toggleMic}>
                        <div className="text-center icon-holder-secondary">
                            <img className='icon-img' src={micon} />
                            {/* <div className="linkContent hide">Mic on</div> */}
                        </div>
                    </div> 
                    )
                }
            })()}
       </div>
    )
}
export default MicScreen;