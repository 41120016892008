import React, { Component, useEffect, useState } from 'react';
import { render } from 'react-dom';
import Modal from 'react-modal';
import { cameraTrack, microphoneTrack } from '../video-screen/index';
import AgoraRTC from "agora-rtc-sdk-ng";
const  VideoSetting = (props) => {
  console.log('modal11', props.modalstatus);
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const [allDevices, setDevices] = useState([]);
  const closeModal = () => {
    setmodalIsOpen(!modalIsOpen);
    //props.setDeviceModal(false)
  };


  useEffect(() => {
    console.log('====')
    setmodalIsOpen(props.modalstatus)
  }, [props.modalstatus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        /* use the stream */
        displayDeviceLabels();
      } catch (err) {
        /* handle the error */
      }
    }
    // call the function
    //fetchData()
    displayDeviceLabels();
  }, []);

  const displayDeviceLabels = () => {
    navigator.mediaDevices.enumerateDevices().then(async (devices) => {
      console.log('devices', devices)
      setDevices(devices);
      if(localStorage.getItem('activeVideoDevice')){
        document.getElementById('video').value = localStorage.getItem('activeVideoDevice');
      }
      if(devices.find(e => e.label == cameraTrack._deviceName)?.deviceId){
        //console.log('cameraTrack===',cameraTrack);
        document.getElementById('video').value = devices.find(e => e.label == cameraTrack._deviceName)?.deviceId;
      }
    });
  };

  const setCustomDevice = (ev, type) => {
    console.log('deviceId=', ev.target.value)
    closeModal();
    if (type == 'video') {
      cameraTrack.setDevice(ev.target.value);
      localStorage.setItem('activeVideoDevice', ev.target.value)
      // cameraTrack = AgoraRTC.createCustomVideoTrack({
      //   mediaStreamTrack: ev.target.value,

      // });
    }
  }
  return (
    <div>

        <div style={{'padding': '0px 25px'}}>
          <div className='audio-lable' style={{'color':'#E82C86'}}>Video</div>
          <select className="form-control audio-select" name="video" id="video" onChange={(e) => setCustomDevice(e, 'video')}>
            {allDevices.filter(rowData => rowData.kind == 'videoinput').map((item, index) => (
              <option value={item.deviceId} key={index}>{item.label}</option>
            ))
            }
          </select>
        </div>
      

    </div>

  );
}


export default VideoSetting;