import React, { useEffect, useState } from 'react';
import AgoraRTC from "agora-rtc-sdk-ng"
import _ from 'lodash';
import ScreenShareimg from '../../../assets/img/icon/present_to_all.svg';
import { client } from '../index';
import { MIX_PANEL_CONST } from '../../../constants/MixpanelConstants';
import { Mixpanel } from '../../../utils/Mixpanel';
const ScreenShare = (props) => {
    const [screenstatus, setscreenstatus] = useState();
    const [appdetails, setappdetails] = useState();
    const [screen, setscreen] = useState();
    const [screenclient, setscreenclient] = useState();
    let searchParams = new URLSearchParams(window.location.search);
    const CHANNEL = _.get(props, 'channelName');
    const dummyId = `${searchParams.get('id')}0000`;
    const UID = parseInt(dummyId);
    const APP_ID = process.env.REACT_APP_ID
    const cname = searchParams.get('cname');
    const query = searchParams.get('id');
    const role = searchParams.get('role');
    const username = searchParams.get('username');
    let TOKEN;
    const screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    useEffect(() => {
        tokenapicall();
    }, []);
    const tokenapicall = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/generatetoken/${CHANNEL}/audience/uid/${UID}`)
            .then(data => {
                return data.json();
            })
            .then(post => {
                //toggleScreenshare(_.get(post, 'token'))

                setappdetails(preValue => ({ ...preValue, ...post }));
            });
    }

    var screenTrack = {};
    const toggleScreenshare = async (TOKEN) => {
        TOKEN = _.get(appdetails, 'token');
        let userAgentString = navigator.userAgent;
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        // Discard Safari since it also matches Chrome
        //props.updateRecordFun(true, props.Users,  () => {});
        if ((chromeAgent) && (safariAgent))
            safariAgent = false;
        // if (safariAgent) {
        //     screenTrack = await AgoraRTC.createScreenVideoTrack({ encoderConfig: "480p_1" }, "auto");
        // }
        // else {
            screenTrack = await AgoraRTC.createScreenVideoTrack({ encoderConfig: "720p_1" }, "auto");
        //}
        //safariStream()
        if(screenTrack._ID || screenTrack._enabled || (screenTrack[0] && (screenTrack[0]._ID || screenTrack[0]._enabled))){
            if(role == "teacher"){
                props.updateRecordFun(false, props.Users,  async () => {

                    props.setScreensharestatus()
                    props.setLeaveFastboard(false);
        
                    await screenClient.join(APP_ID, CHANNEL, TOKEN, UID);
                    await screenClient.publish(screenTrack);
                    setscreen(screenTrack);
                    setscreenclient(screenClient);
                    // props.setscreenstatus(true)
                    setscreenstatus(false);
                    Mixpanel.track(MIX_PANEL_CONST.SCREEN_SHARE_SUCCESS, {username: username, role : role, channelName : cname, id : query, ...screenTrack});
        
                    });
            }else{
                props.setScreensharestatus()
                props.setLeaveFastboard(false);
    
                await screenClient.join(APP_ID, CHANNEL, TOKEN, UID);
                await screenClient.publish(screenTrack);
                setscreen(screenTrack);
                setscreenclient(screenClient);
                // props.setscreenstatus(true)
                setscreenstatus(false);
                Mixpanel.track(MIX_PANEL_CONST.SCREEN_SHARE_SUCCESS, {username: username, role : role, channelName : cname, id : query, ...screenTrack});
            }
            
        }
        
        return screenClient;
        // }

    }
    const safariStream = () => {
        // Get the <video> element
        var video = document.getElementById('video-call-stream');
        // Default constrains
        var constraints = { audio: true, video: true };
        navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess);

        var handleSuccess = function (stream) {
            video.srcObject = stream;
        };
    }
    screenTrack = screen;
    const togglestop = async (e) => {
        props.setScreensharestatus(UID);
        screenTrack = screen;
        if (screenTrack.length) {
            screenTrack.forEach(element => {
                element.stop();
                element.close();
            });
        } else {
            screenTrack.stop();
            screenTrack.close();
        }
        screenclient.removeAllListeners();
        await screenclient.leave();
        if(role == "teacher"){
           props.updateRecordFun(true, props.Users,  async () => {});
        }        
        setscreenstatus(true);
        props.setLeaveFastboard(true);
    }

    useEffect(() => {
        if (!_.isEmpty(screenTrack)) {
            // screenTrack.addEventListener('ended', () => {
            //     console.log('event lisner',screenTrack);
            //     togglestop();

            //   })
        }
    }, [screenTrack])


    // stream.getVideoTracks()[0].onended = function () {
    //     // doWhatYouNeedToDo();
    //     togglestop();
    //   };
    return (
        <div>
            {(() => {
                if (screenstatus === false) {
                    return (
                        <div className="links" title='You are presenting' onClick={togglestop}>
                            <div className="text-center icon-holder-primary">
                                <img className='icon-img' src={ScreenShareimg} />
                                {/* <div className="linkContent hide">Stop Screen</div> */}
                            </div>
                        </div>
                    )
                } else {
                    if (props.Users && props.Users.length < 3) {
                        return (
                            <div className="links" title='Present now' onClick={() => { toggleScreenshare(); Mixpanel.track(MIX_PANEL_CONST.SCREEN_SHARE_CLICKED, {username: username, role : role, channelName : cname, id : query});
                         }}>
                                <div className="text-center icon-holder-secondary">
                                    {/* <i class="fa fa-screencast"></i> */}
                                    <img className='icon-img' src={ScreenShareimg} />
                                    {/* <div className="linkContent hide">Share Screen</div> */}
                                </div>
                            </div>
                        )
                    }else{
                        return (<div></div>)
                    }
                    
                }
            })()}
        </div>
    )
}
export default ScreenShare;